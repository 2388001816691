import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as ChartLine} from '../../../../assets/icons/chart-line.svg'
import {ReactComponent as EstimatedReturnIcon} from '../../../../assets/icons/estimated-return.svg'
import {ReactComponent as PieChart} from '../../../../assets/icons/pie-chart-white.svg'
import {ReactComponent as Receive} from '../../../../assets/icons/receive.svg'
import {ReactComponent as TokenPriceIcon} from '../../../../assets/icons/token-price.svg'
import Button from '../../../../components/Button/Button'
import Card from '../../../../components/Card/Card'
import ItemProperty from '../../../../components/ItemCard/components/ItemProperty/ItemProperty'
import {CURRENCY_SYMBOL} from '../../../../constants/currencies'
import {useProjectSlice} from '../../../../store/slices/project'
import {formatNumberToString} from '../../../../utils/formatNumber'
import TokenCounter from '../TokenCounter/TokenCounter'

import styles from './Tokenomic.module.scss'

interface ITokenomicProps extends WithTranslation {
  investAction: () => void
  straightCorners?: boolean
}

const Tokenomic = ({t, investAction, straightCorners}: ITokenomicProps) => {
  const {
    tokenomic: {
      total_objective,
      token,
      capital_gain_after_sale,
      variable_capital_gain_after_sale,
      undefined_variable_rate,
      variable_rate,
      fixed_annual_rate,
    },
  } = useProjectSlice()

  return (
    <Card className={styles.card} withShadow straightCorners={straightCorners}>
      <div
        className={classNames(styles.investmentBox, {[styles.straightCorners]: straightCorners})}>
        {!!total_objective && (
          <ItemProperty
            icon="$"
            label={`${t('item.totalFund')}`}
            property={
              <>
                <span>{formatNumberToString(+total_objective || 0, 2)}</span>{' '}
                {CURRENCY_SYMBOL[token?.currency]}
              </>
            }
            oneLine
          />
        )}
        {!!token?.price && (
          <ItemProperty
            icon={<TokenPriceIcon className={styles['path-stroke']} />}
            label={`${t('item.tokenPrice')}`}
            property={
              <>
                <span>{formatNumberToString(+token?.price || 0, 2)}</span>{' '}
                {CURRENCY_SYMBOL[token?.currency]}
              </>
            }
            oneLine
          />
        )}
        {!!variable_rate && (
          <ItemProperty
            className={{labelFontSize: styles.bold}}
            icon={<PieChart className={styles['path-fill']} />}
            label={`${t('item.variableRate')}`}
            property={
              <span className={styles.primary}>{formatNumberToString(+variable_rate, 2)}%</span>
            }
            oneLine
          />
        )}
        {!!fixed_annual_rate && (
          <ItemProperty
            className={{labelFontSize: styles.bold}}
            icon={<PieChart className={styles['path-fill']} />}
            label={`${t('item.fixedAnnualRate')}`}
            property={
              <span className={styles.primary}>{formatNumberToString(+fixed_annual_rate, 2)}%</span>
            }
            oneLine
          />
        )}
        {!!capital_gain_after_sale && (
          <ItemProperty
            className={{labelFontSize: styles.bold}}
            icon={<EstimatedReturnIcon className={styles['path-stroke-fill']} />}
            label={`${t('item.capitalGainAfterSale')}`}
            property={
              <span className={styles.primary}>
                {formatNumberToString(+capital_gain_after_sale || 0, 2)}%
              </span>
            }
            oneLine
          />
        )}
        {!!variable_capital_gain_after_sale && (
          <ItemProperty
            className={{labelFontSize: styles.bold}}
            icon={<Receive className={styles['path-fill']} />}
            label={`${t('item.capitalGainAfterSale')}`}
            property={<span>Variable</span>}
            oneLine
          />
        )}
        {!!undefined_variable_rate && (
          <ItemProperty
            icon={<ChartLine className={styles['path-fill']} />}
            label={`${t('item.undefinedVariableRate')}`}
            property={<span>Variable</span>}
            oneLine
          />
        )}

        <div className={styles.line} />
        <div className={styles.footer}>
          <TokenCounter />
          <Button isCallToAction className={styles.button} onClick={() => investAction()}>
            {t('invest')}
          </Button>
        </div>
      </div>
    </Card>
  )
}

export default withTranslation()(Tokenomic)
