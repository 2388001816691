import {ReactNode, useCallback, useEffect, useMemo} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as Arrow} from '../../assets/icons/arrow-up.svg'
import {ReactComponent as Company} from '../../assets/icons/company.svg'
import {ReactComponent as Person} from '../../assets/icons/person.svg'
import {PATHS} from '../../constants/paths'
import {magic} from '../../libs/magic'
import {PersonType} from '../../services/interfaces/ICustomerInfo'
import {useCustomerSlice} from '../../store/slices/customer'
import {usePersonFormsSlice} from '../../store/slices/personForms'

import styles from './PersonalInformation.module.scss'

interface IPersonalInformation extends WithTranslation {
  className?: string
  onlyEssentialData?: boolean
}
interface IFormOption {
  key: PersonType
  label: string
  icon: ReactNode
  url: string
}
const PersonalInformation = ({className, t}: IPersonalInformation) => {
  const navigate = useNavigate()
  const {initForms} = usePersonFormsSlice()
  const {customer_info, setCustomerData} = useCustomerSlice()

  const formOptions: IFormOption[] = useMemo<IFormOption[]>(
    () => [
      {
        key: 'NATURAL',
        label: t('personalInformation.person.label'),
        icon: <Person className={styles.person} />,
        url: PATHS.PERSONAL_INFORMATION_PERSON,
      },
      {
        key: 'LEGAL',
        label: t('personalInformation.provider.label'),
        icon: <Company className={styles.company} />,
        url: PATHS.PERSONAL_INFORMATION_COMPANY,
      },
    ],
    [t],
  )

  const selectFormType = (formOption: IFormOption) => {
    initForms(formOption.key)
    navigate(formOption.url)
  }

  useEffect(() => {
    const naturalFormOption = formOptions.find(option => option.key === 'NATURAL')
    if (naturalFormOption) {
      selectFormType(naturalFormOption)
    }
  }, [])

  const getUserEmail = useCallback(async () => {
    if (!!customer_info.email) return
    const {email} = await magic.user.getMetadata()
    setCustomerData({customer_info: {...customer_info, email: email || ''}})
  }, [magic, customer_info, setCustomerData])

  useEffect(() => {
    getUserEmail()
    const naturalFormOption = formOptions.find(option => option.key === 'NATURAL')
    if (naturalFormOption) {
      selectFormType(naturalFormOption)
    }
  }, [])

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.header}>
        <span className={styles.title}>{t('personalInformation.accountType')}</span>
        <span className={styles.clarification}>{t('personalInformation.yourProfile')}</span>
      </div>
      {formOptions.map(formOption => (
        <div
          key={formOption.key}
          className={styles.formOptionContainer}
          onClick={() => selectFormType(formOption)}>
          <div
            className={classNames(
              styles.formOption,
              (formOption.key === 'NATURAL' && !customer_info?.person_type && styles.highlighted) ||
                '',
            )}>
            <div className={styles.box}>
              <div className={classNames(styles.circle)}>{formOption.icon}</div>
              <div className={classNames(styles.textContainer)}>
                <span>{formOption.label}</span>
              </div>
              <Arrow className={styles.arrow} />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default withTranslation()(PersonalInformation)
