import {useCallback, useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import classNames from 'classnames'

import {featuredItemsSettings} from '../../constants/sliderSettings'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import {projectApi} from '../../services'
import {IAddress} from '../../services/interfaces/IAddress'
import {IProject} from '../../services/interfaces/IProject'
import {useFeaturedProjectsSlice} from '../../store/slices/featuredProjects'
import Card from '../Card/Card'
import ItemCard from '../ItemCard/ItemCard'
import Slider from '../Slider/Slider'

import FeaturedItemsSkeleton from './skeleton/FeaturedItemsSkeleton'

import styles from './FeaturedItems.module.scss'

interface IFeaturedItems {
  design?: 'first' | 'second'
  straightCorners?: boolean
}
const FeaturedItems = ({design = 'first', straightCorners}: IFeaturedItems) => {
  const {t} = useTranslation()
  const {featuredProjects, setProjects} = useFeaturedProjectsSlice()
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [{city, country}, setAddress] = useState<IAddress>({} as IAddress)
  const getProjects = useCallback(async () => {
    const projectData = await projectApi.getProjects()
    setProjects(projectData.filter(project => project.is_opportunity))
  }, [])

  const renderProject = (project: IProject, slideClass: string) => (
    <Card key={project.name} className={slideClass}>
      <ItemCard
        itemData={project}
        layout="row"
        showPercentageFloating
        design={design}
        straightCorners={straightCorners}
      />
    </Card>
  )

  useEffect(() => {
    getProjects()
  }, [])

  useEffect(() => {
    if (!!featuredProjects?.length) setAddress(featuredProjects[activeIndex].address)
  }, [featuredProjects, activeIndex])

  return (
    <BannerSection className={styles.section} id="featuredItems">
      <BannerContainer className={classNames(styles.container, styles[design])}>
        {!featuredProjects?.length && <FeaturedItemsSkeleton design={design} />}
        {!!featuredProjects?.length && (
          <div className={classNames(styles.wrapContainer, styles[design])}>
            <div className={classNames(styles.textWrapper, styles[design])}>
              <span className={styles.title}>{t('featuredItems.title')}</span>
              {design === 'first' && (
                <>
                  <span className={styles.description}>
                    <Trans i18nKey="featuredItems.description">
                      Become a professional investor in <span>B+</span>
                    </Trans>
                  </span>
                  <div className={styles.border} />
                  <span className={styles.footer}>
                    {t('featuredItems.footer')} <br /> {`${city || ''} - ${country || ''}`}
                  </span>
                </>
              )}
            </div>
            <Slider
              slides={featuredProjects || []}
              className={classNames(styles.slider, styles[design], {
                [styles.straightCorners]: straightCorners,
              })}
              customClasses={{
                swiperSlide: styles.swiperSlide,
                slide: styles.slide,
                bulletActive: styles.bulletActive,
                bullet: styles.bullet,
              }}
              renderSlide={renderProject}
              settings={featuredItemsSettings}
              showBullet
              onActiveIndexChange={setActiveIndex}
            />
          </div>
        )}
      </BannerContainer>
    </BannerSection>
  )
}

export default FeaturedItems
